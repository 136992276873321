import React from "react";
import { View, ScrollView } from "react-native";
import colors from "../assets/colors/colors";
import Navbar from "../components/Navbar";
import { LatoRegular } from "../components/Text";
import Footer from "../components/Footer";
import { Link, useLocation } from "react-router-dom";

export default function PaymentDoneScreen() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paymentStatus = queryParams.get('paymentStatus');

  return (
    <View>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ height: "100vh" }}
      >
        <Navbar backgroundColor={colors.whiteFF} />
        <View
          style={{
            marginVertical: 48,
            minHeight: 300,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LatoRegular
            style={{ fontSize: 24, lineHeight: 32, marginBottom: 8 }}
          >
            Your Payment Status: {paymentStatus}
          </LatoRegular>
          <Link to="/contact-us">
            <LatoRegular
              style={{
                fontSize: 14,
                lineHeight: 20,
                color: colors.grey6C,
              }}
            >
              Please click here to contact us if you need assistance.
            </LatoRegular>
          </Link>
        </View>
        <Footer />
      </ScrollView>
    </View>
  );
}
