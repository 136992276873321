import React from "react";
import { View, useWindowDimensions } from "react-native";
import GridLoading from "./GridLoading";
import { getDeviceType } from "../../tools/Interface";
import { times } from "lodash";

function GridWrapper({
  loading,
  style = {},
  isCrush = false,
  catalog = false,
  divider = 1,
  children,
}) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const gap = isPhone ? 24 : width < 1350 ? 50 : width < 1500 ? 150 : 220;
  const containerWidth = width - gap * 2;

  return (
    <View
      style={{
        ...style.container,
        flex: 1,
        paddingHorizontal: isCrush ? 0 : gap,
        paddingTop: 48,
        paddingBottom: catalog ? (isPhone ? 24 : 48) : 80,
        width: containerWidth,
      }}
    >
      {children}
      {loading &&
        times(2, (x) => (
          <View style={style.row} key={x}>
            {times(divider, (i) => (
              <GridLoading key={i} divider={divider} index={i} />
            ))}
          </View>
        ))}
    </View>
  );
}

export default GridWrapper;
