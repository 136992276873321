import React from "react";
import {
  Animated,
  TouchableOpacity,
  View,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import colors from "../../assets/colors/colors";
import { GoSearch } from "react-icons/go";
import { GrClose } from "react-icons/gr";
import { FaChevronRight } from "react-icons/fa";
import { getDeviceType } from "../../tools/Interface";
import { useNavigate } from "react-router";
import { LatoBold, LatoRegular } from "../Text";
import Buttons from "../Buttons";
import { Linking } from "react-native";

function MainBurgerMenu({
  opacity,
  openBurger = false,
  setOpenBurger = () => {},
  setOpenSearch = () => {},
  burgerArr = [],
  user = {},
  openSubBurger = false,
  subBurgerIndex = false,
  setOpenSubBurger = () => {},
  setSubBurgerIndex = () => {},
}) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const navigate = useNavigate();

  const phoneAdditionalArr = [
    {
      title: user?.id ? "Account" : "Login",
      path: user?.id ? "/account/orders" : "/login",
      state: user?.id ? { idx: 0, index: 1 } : {},
    },
    { title: "Contact Us", path: "/contact-us" },
    { title: "FAQ", path: "/faq" },
    { title: "Terms and conditions", path: "/terms-conditions" },
  ];

  const animatedHeight = opacity.interpolate({
    inputRange: [0, 1],
    outputRange: [0, openBurger ? 0.9 * height : 300],
  });

  return (
    <Animated.View
      style={{
        borderTopColor: colors.greyE6,
        borderTopWidth: 1,
        position: "absolute",
        zIndex: 10,
        top: 0,
        width,
        height: isTablet ? height : animatedHeight,
        backgroundColor: colors.whiteFF,
        shadowColor: !openBurger ? colors.whiteFF : colors.grey33,
        shadowOffset: { height: 1, width: 0 },
        shadowOpacity: 0.15,
        shadowRadius: 2,
        opacity,
      }}
    >
      <ScrollView
        showVerticalScrollIndicator={false}
        style={{ height: isTablet ? height : animatedHeight }}
        scrollEventThrottle={16}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginHorizontal: isPhone ? 16 : 24,
            paddingVertical: 24,
            paddingTop: isPhone ? 24 : 40,
            borderBottomWidth: 1,
            borderBottomColor: colors.greyE6,
            marginVertical: 16,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              setOpenBurger(false);
            }}
          >
            <GrClose size={16} />
          </TouchableOpacity>
          <LatoRegular style={{ fontSize: 16, lineHeight: 24, marginLeft: 16 }}>
            MENU
          </LatoRegular>
        </View>
        <TouchableOpacity
          onPress={() => {
            setOpenBurger(false);
            setOpenSearch(true);
          }}
          style={{
            paddingHorizontal: 12,
            paddingVertical: 8,
            borderColor: colors.grey90,
            borderWidth: 1,
            flexDirection: "row",
            alignItems: "center",
            marginHorizontal: isPhone ? 16 : 24,
            borderRadius: 4,
            marginVertical: 16,
          }}
        >
          <GoSearch size={isPhone ? 20 : 24} color={colors.grey90} />
          <LatoRegular
            style={{
              fontSize: 14,
              lineHeight: 20,
              color: colors.grey90,
              marginLeft: 8,
            }}
          >
            Search by brand, product name.
          </LatoRegular>
        </TouchableOpacity>
        <View
          style={
            isTablet
              ? {
                  marginBottom: 16,
                  paddingBottom: 16,
                  borderBottomColor: colors.greyE6,
                  borderBottomWidth: 1,
                  marginHorizontal: isPhone ? 16 : 20,
                }
              : {}
          }
        >
          {burgerArr.map((item, index) => {
            return burgerArr.length - 1 === index ? (
              <Buttons
                label={"SELL WITH US"}
                isBlack
                noBorder
                onPress={() => {
                  navigate("/sell-with-us/consignment");
                  setOpenBurger(false);
                  setOpenSubBurger(false);
                }}
                containerStyle={{ marginVertical: 16 }}
              />
            ) : (
              <View
                key={index}
                style={{
                  borderBottomColor:
                    !isTablet && index === burgerArr.length - 1
                      ? colors.greyE6
                      : "transparent",
                  borderBottomWidth: index === burgerArr.length - 1 ? 1 : 0,
                  paddingVertical: 16,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  {Boolean(openSubBurger && subBurgerIndex === index) && (
                    <View
                      style={{
                        backgroundColor: colors.black00,
                        borderRadius: "100%",
                        width: 12,
                        height: 12,
                        marginLeft: -28,
                        marginRight: 14,
                      }}
                    />
                  )}
                  <TouchableOpacity
                    onPress={() => {
                      if (typeof item === "object") {
                        if (item.menu) {
                          setOpenBurger(false);
                          setOpenSubBurger(true);
                          setSubBurgerIndex(index);
                        } else if (item.externalLink) {
                          // Handle external link navigation
                          Linking.openURL(item.externalLink).catch(err =>
                            console.error("Failed to open URL:", err)
                          );
                          setOpenBurger(false); // Close the burger menu
                        } else {
                          if (item.title === "Sale") {
                            navigate(
                              "/products",
                              { state: { discounted: true } }
                            );
                          } else if (item.title === "New Arrivals") {
                            navigate("/products");
                          } else if (item.title === "Catalogue") {
                            navigate("/catalogue");
                          } else if (item.title === "Designers") {
                            navigate("/designers");
                          }
                          setOpenBurger(false);
                        }
                      } else {
                        setOpenBurger(false);
                        index > 0
                          ? navigate(`/products/designer/${item}`)
                          : navigate("/products");
                      }
                    }}
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <LatoBold
                      style={{
                        fontSize: 16,
                        lineHeight: 24,
                        color: item.title === "Sale" ? colors.redAD : colors.grey33,
                        textTransform: "uppercase",
                      }}
                    >
                      {typeof item === "object" ? item?.title : item}
                    </LatoBold>
                    {Boolean(!item.noChevron) && (
                      <FaChevronRight size={14} color={colors.grey90} />
                    )}
                  </TouchableOpacity>
                </View>
              </View>
            );
          })}
        </View>
        {Boolean(isTablet) &&
          phoneAdditionalArr.map(
            ({ title, path, state, press = () => {} }, index) => {
              return (
                <TouchableOpacity
                  onPress={
                    path
                      ? () => {
                          setOpenSubBurger(false);
                          setOpenBurger(false);
                          navigate(path, { state });
                        }
                      : press
                  }
                  key={index}
                  style={{
                    marginVertical: 12,
                    marginHorizontal: isPhone ? 16 : 24,
                  }}
                >
                  <LatoRegular
                    style={{
                      color: colors.grey6C,
                      fontSize: 16,
                      lineHeight: 24,
                      textTransform: "uppercase",
                    }}
                  >
                    {title}
                  </LatoRegular>
                </TouchableOpacity>
              );
            }
          )}
      </ScrollView>
    </Animated.View>
  );
}

export default MainBurgerMenu;
